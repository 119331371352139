import { FC, forwardRef, ReactElement, ReactNode, Ref } from 'react';
import { PopupActions } from 'reactjs-popup/dist/types';

import 'reactjs-popup/dist/index.css';

import {
  StyledModal,
  ModalHeader,
  ModalHeaderCloseButton,
} from './modal.styles';
import { Svg } from '../svg-icon/svg-icon.styles';

export interface IModalProps {
  trigger: ReactElement;
  children: ((close: () => void) => ReactNode) | ReactNode;
  modalTitle: string;
  ref?: Ref<PopupActions>;
}

const Modal: FC<IModalProps> = forwardRef(
  ({ trigger, children, modalTitle }, ref) => {
    return (
      <StyledModal
        ref={ref}
        trigger={trigger}
        modal
        closeOnDocumentClick={false}
        closeOnEscape
        lockScroll
      >
        {(close: () => void) => (
          <>
            <ModalHeader>
              <p>{modalTitle}</p>

              <ModalHeaderCloseButton onClick={close}>
                <Svg small black viewBox="0 0 14 14">
                  <path d="M13.3.7c-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3L7 5.6 2.1.7C1.9.5 1.7.4 1.4.4S.9.5.7.7c-.4.4-.4 1 0 1.4L5.6 7 .7 11.9c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0L7 8.4l4.9 4.9c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L8.4 7l4.9-4.9c.4-.4.4-1 0-1.4z"></path>
                </Svg>
              </ModalHeaderCloseButton>
            </ModalHeader>

            {typeof children === 'function' ? children(close) : children}
          </>
        )}
      </StyledModal>
    );
  }
);

export default Modal;
