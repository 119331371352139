import styled from 'styled-components';

import { colors } from '~/lib/colors';

export const DesktopSidebarNav = styled.nav`
  background-color: ${colors.white};
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 2.4rem;
  width: 25rem;
  margin-right: 3rem;
  height: 100%;
`;

export const DesktopSidebarNavItem = styled.li<{ isActive: boolean }>`
  color: ${colors.gray};
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover {
    background-color: #e6fff7;
  }

  & > a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 1.6rem;
  }

  & svg {
    margin-right: 1rem;
    fill: ${colors.gray};
    transition: fill 0.2s ease-in-out;
  }

  ${({ isActive }) =>
    isActive &&
    `
    background-color: ${colors.gray1};
    color: ${colors.white};

    &:hover{
      background-color: ${colors.gray};
    }

    &  svg{
      fill: ${colors.white};
    }
  `}
`;
