import { FC } from 'react';

import { DashboardItemBorders } from '../dashboard-item-loader/dashboard-item-loader.component';

const ModalContentLoader: FC = () => {
  return (
    <div
      style={{
        padding: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <DashboardItemBorders />
    </div>
  );
};

export default ModalContentLoader;
