import LogRocket from 'logrocket';
import * as Sentry from '@sentry/nextjs';

import type { User } from '~/types/user.type';

const setUserForLogging = (user: User) => {
  LogRocket.identify(String(user?.pk), {
    name: `${user?.last_name} ${user?.first_name}`,
    email: user?.email,
  });

  Sentry.configureScope((scope) => {
    scope.setUser({
      id: String(user?.pk),
      email: user?.email,
      lastName: user?.last_name,
      firstName: user?.first_name,
    });
  });
};

export default setUserForLogging;
