import { FC, ReactNode, useEffect, useState } from 'react';
import Head from 'next/head';

import { Main, MainContainer } from './general-layout.styles';

import ProtectedLayout from '../protected-layout/protected-layout.component';
import Header from '~/components/header/header.component';
import DesktopSidebar from '~/components/desktop-sidebar/desktop-sidebar.component';
import Button from '~/components/button/button.component';

import useMediaQuery from '~/hooks/useMediaQuery';

interface IGeneralLayoutProps {
  children: ReactNode;
  pageTitle: string;
  withBackBtn?: boolean;
}

const GeneralLayout: FC<IGeneralLayoutProps> = ({
  children,
  pageTitle,
  withBackBtn,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  const isViewportGreaterThan800px = useMediaQuery('(min-width: 50em)');

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <ProtectedLayout>
      <Head>
        <title>{pageTitle}</title>
      </Head>

      <Header />

      <MainContainer>
        {isMounted && isViewportGreaterThan800px && <DesktopSidebar />}

        <Main>
          {withBackBtn && (
            <Button semitransparentBg onClick={() => history.back()}>
              Go back
            </Button>
          )}

          {children}
        </Main>
      </MainContainer>
    </ProtectedLayout>
  );
};

export default GeneralLayout;
