import { FC } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import {
  DesktopSidebarNav,
  DesktopSidebarNavItem,
} from './desktop-sidebar.styles';

import SvgIcon from '../svg-icon/svg-icon.component';

import routes from '~/helpers/routes';

const DesktopSidebar: FC = () => {
  const { pathname } = useRouter();

  return (
    <DesktopSidebarNav>
      <ul>
        <DesktopSidebarNavItem
          isActive={
            pathname.startsWith(routes.animals()) ||
            pathname.startsWith(routes.herds())
          }
        >
          <Link href={routes.animals()}>
            <a>
              <SvgIcon iconName="folder" small /> Animals
            </a>
          </Link>
        </DesktopSidebarNavItem>

        <DesktopSidebarNavItem isActive={pathname.startsWith(routes.alerts())}>
          <Link href={routes.herdAlerts()}>
            <a>
              <SvgIcon iconName="clock" small />
              Events
            </a>
          </Link>
        </DesktopSidebarNavItem>

        <DesktopSidebarNavItem isActive={pathname.startsWith(routes.care())}>
          <Link href={routes.vaccinations()}>
            <a>
              <SvgIcon iconName="stack-rectangles" small />
              Care
            </a>
          </Link>
        </DesktopSidebarNavItem>

        <DesktopSidebarNavItem
          isActive={pathname.startsWith(routes.inventoryItems())}
        >
          <Link href={routes.inventoryItems()}>
            <a>
              <SvgIcon iconName="clipboard" small />
              Inventory
            </a>
          </Link>
        </DesktopSidebarNavItem>

        <DesktopSidebarNavItem isActive={pathname.startsWith(routes.sales())}>
          <Link href={routes.animalSales()}>
            <a>
              <SvgIcon iconName="price-tag" small />
              Sales
            </a>
          </Link>
        </DesktopSidebarNavItem>

        <DesktopSidebarNavItem
          isActive={pathname.startsWith(routes.pastures())}
        >
          <Link href={routes.pastures()}>
            <a>
              <SvgIcon iconName="leaf" small />
              Pasture
            </a>
          </Link>
        </DesktopSidebarNavItem>

        <DesktopSidebarNavItem
          isActive={pathname.startsWith(routes.breedings())}
        >
          <Link href={routes.breedings()}>
            <a>
              <SvgIcon iconName="egg" small />
              Breedings
            </a>
          </Link>
        </DesktopSidebarNavItem>

        <DesktopSidebarNavItem
          isActive={pathname.startsWith(routes.subscriptions())}
        >
          <Link href={routes.subscriptions()}>
            <a>
              <SvgIcon iconName="dollar" small />
              Subscription
            </a>
          </Link>
        </DesktopSidebarNavItem>
      </ul>
    </DesktopSidebarNav>
  );
};

export default DesktopSidebar;
