import styled, { keyframes } from 'styled-components';

import { colors } from '~/lib/colors';

import Popup from 'reactjs-popup';

const anvil = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
    visibility: hidden;
  }
  1% {
    transform: scale(0.9);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
`;

export const StyledModal = styled(Popup)`
  &-overlay {
    background-color: rgba(1, 1, 1, 0.74);
    overflow-y: auto;
    padding: 1rem;
  }

  &-content {
    animation: ${anvil} 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    border-radius: 1rem;
    padding: 2rem;
    width: min(60rem, 90vw);
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;

  & > p {
    margin-right: 1rem;
    text-transform: capitalize;
    color: ${colors.primary};
    font-size: 1.8rem;
  }
`;

export const ModalHeaderCloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white1};
  border: none;
  outline: none;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 5px;
  cursor: pointer;
  outline: transparent dashed 1.5px;
  outline-offset: 2px;
  transition-property: background-color, outline-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  &:focus-visible {
    outline-color: ${colors.black};
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
`;
