import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Borders } from '../loaders/page-loader.component';

import NoItems from '../no-items/no-items.component';

import { colors } from '~/lib/colors';

interface IDashboardItemLoaderProps {
  isLoading: boolean;
  loadingText?: string;
  hasResult: boolean;
  children: ReactNode;
  noItemsMessage?: string;
}

const DashboardItemLoader: FC<IDashboardItemLoaderProps> = ({
  isLoading,
  loadingText,
  hasResult,
  children,
  noItemsMessage,
}) => {
  if (isLoading) {
    return (
      <Container>
        <DashboardItemBorders />

        {loadingText && <p>{loadingText}</p>}
      </Container>
    );
  }

  if (!isLoading && !hasResult) {
    return (
      <Container>
        <NoItems message={noItemsMessage ?? 'No data to show'} />
      </Container>
    );
  }

  return <>{children}</>;
};

const Container = styled.div`
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  & > p {
    margin-top: 3rem;
  }
`;

export const DashboardItemBorders = styled(Borders)`
  &:after,
  &:before {
    border: 0.4rem solid ${colors.primary};
  }

  &:before {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  &:after {
    border-right-color: transparent;
    border-top-color: transparent;
  }
`;

export default DashboardItemLoader;
