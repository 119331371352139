import styled from 'styled-components';

import { colors } from '~/lib/colors';

export const NoItemsContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoItemsImageContainer = styled.div`
  position: relative;
  width: 30rem;
  height: 25rem;

  @media only screen and (min-width: 43.75em) {
    width: 35rem;
  }
`;

export const NoItemsText = styled.p`
  font-size: 1.4rem;
  background-color: ${colors.white3};
  text-align: center;
  border-radius: 2px;
  padding: 1rem 2rem;
  margin: 1rem 0 0;
`;
