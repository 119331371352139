import { useQuery } from 'react-query';
import { useRouter } from 'next/router';

import ReactQueryKeys from '../keys';
import { apiV1 } from '../utils';

import useStore from '../../store';

import routes from '~/helpers/routes';

import setUserForLogging from '~/utils/setUserForLogging';

import type { User } from '../../types/user.type';

export const getUser = async (): Promise<User> => {
  const response = await apiV1.get('/user/');

  return response?.data;
};

const useCheckUserSession = () => {
  const router = useRouter();

  const currentUser = useStore((state) => state.currentUser);

  const updateUser = useStore((state) => state.updateUser);

  return useQuery(ReactQueryKeys.CHECK_USER_SESSION, getUser, {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: !currentUser,
    onSuccess: (data) => {
      updateUser(data);

      setUserForLogging(data);
    },
    onError: () => {
      router.push(routes.login());
    },
    retry: false,
  });
};

export default useCheckUserSession;
