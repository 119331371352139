import { FC, ReactNode } from 'react';
import { useRouter } from 'next/router';

import PageLoader from '~/components/loaders/page-loader.component';

import useStore from '~/store';

import useCheckUserSession from '~/react-query/queries/useCheckUserSession';

interface IProtectedLayoutProps {
  children: ReactNode;
}

const ProtectedLayout: FC<IProtectedLayoutProps> = ({ children }) => {
  const currentUser = useStore((state) => state.currentUser);

  const router = useRouter();

  const { isLoading } = useCheckUserSession();

  if (isLoading) return <PageLoader />;

  if (!isLoading && !currentUser) {
    window.location.href = `${window.origin}/log-in?redirectTo=${router.asPath}`;

    return <PageLoader />;
  }

  return <>{children}</>;
};

export default ProtectedLayout;
