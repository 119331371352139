import { FC } from 'react';
import Image from 'next/image';

import {
  NoItemsContainer,
  NoItemsImageContainer,
  NoItemsText,
} from './no-items.styles';

const NoItems: FC<{ message: string }> = ({ message }) => {
  return (
    <NoItemsContainer>
      <NoItemsImageContainer>
        <Image
          src="/empty.svg"
          layout="fill"
          objectFit="contain"
          alt="an illustration of a person checking an empty folder"
        />
      </NoItemsImageContainer>

      <NoItemsText>{message}</NoItemsText>
    </NoItemsContainer>
  );
};

export default NoItems;
